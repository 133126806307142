import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .init({
    // Remove resources from here
    // lng: "en",  {
    supportedLngs: ["en", "es", "fr", "ru", "zh", "ko", "ar", "lo", "ms", "vi"],
    // Allows "en-US" and "en-UK" to be implicitly supported when "en"
    // is supported
    // nonExplicitSupportedLngs: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;