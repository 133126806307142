import React from "react";
import { Container, Divider, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GenericLogo from "./Utils/GenericLogo";

const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("about")} | MyEmbassyMyConsulate</title>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content={t("about")} />
      </Helmet>
      <GenericLogo />
      <Container textAlign="justified">
        <Divider horizontal>
          <Header as="h1" textAlign="center">
            {t("about")}
          </Header>
        </Divider>

        <Container textAlign="center">
          <p>
            <b>
              {t(
                "world-embassies-high-commissions-consulates-and-visa-centers"
              )}
            </b>
          </p>
          <br />
        </Container>
        <p>{t("about-paragraph-1")}</p>
        <p>{t("about-paragraph-2")}</p>
        <p>{t("about-paragraph-3")}</p>
        <p>{t("about-paragraph-4")}</p>
      </Container>
    </div>
  );
};

export default About;
