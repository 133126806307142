import React, { Component } from "react";
import SearchSelection from "./Utils/SearchSelection";
import { Redirect } from "react-router-dom";
import EmbassyList from "./EmbassyList";
import { Container, Divider, Segment, Header } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import CustomCountryLogo from "./CustomCountryLogo";
import { EmbassyAPIService } from "../services/EmbassyAPIService";
import HomeFlagsWidget from "./Utils/HomeFlagsWidget";
import { BuildCountriesList, IsFound } from "./Utils/Utils";

const embassyAPIService = new EmbassyAPIService();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      embassies: [],
      results: null,
      showResult: false,
      country: "",
      embassy: "",
      redirect: false,
      newPath: "/" + props.i18n.language,
      isDisabled: true,
      supportedEmbassies: [],
    };
  }

  componentDidMount() {
    // Fetch the available list of embassies and countries.
    embassyAPIService
      .getAllEmbassiesAndCountries(this.props.i18n.language)
      .then((data) => {
        if (data && data.embassies && data.countries) {
          this.setState({
            embassies: BuildCountriesList(data.embassies),
            countries: BuildCountriesList(data.countries),
            supportedEmbassies: data.embassies,
          });
          let keys = this.props.match.params;
          console.log(this.props.match);
          console.log(keys);
          if (keys.id) {
            if (IsFound(data.embassies, keys.id)) {
              if (keys.country) {
                if (IsFound(data.countries, keys.country)) {
                  this.fetchAndLoadSomeEmbassies(keys.id, keys.country);
                } else {
                  this.setState({
                    redirect: true,
                    newPath: "/" + this.props.i18n.language,
                  });
                }
              } else {
                this.fetchAndLoadAllEmbassies(keys.id);
              }
            } else {
              this.setState({
                redirect: true,
                newPath: "/" + this.props.i18n.language,
              });
            }
          } else {
            this.setState({
              redirect: true,
              newPath: "/" + this.props.i18n.language,
            });
          }
        }
      })
      .catch(console.log);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props !== prevProps &&
      this.props.location.state &&
      this.props.location.state !== prevProps.location.state
    ) {
      this.fetchAndLoadAllEmbassies(this.props.location.state);
    }
  }

  selectEmbassy = (e, d) => {
    if (d.value === "") {
      this.setState({
        results: null,
        newPath: "/" + this.props.i18n.language,
        embassy: "",
        country: "",
        isDisabled: true,
      });
      return;
    }

    this.fetchAndLoadAllEmbassies(d.value);
  };

  fetchAndLoadAllEmbassies = (embassy) => {
    console.log(this.props.i18n.language + "/search/" + embassy);
    embassyAPIService
      .getAllEmbassies(embassy, this.props.i18n.language)
      .then((data) => {
        this.setState({
          results: data,
          embassy: embassy,
          country: "",
          showResult: true,
          redirect: true,
          newPath: "/" + this.props.i18n.language + "/search/" + embassy,
          isDisabled: false,
        });
      })
      .catch(console.log);
  };

  selectCountry = (e, d) => {
    if (d.value === "") {
      this.setState({
        results: null,
        newPath: "/" + this.props.i18n.language,
        embassy: "",
        country: "",
        isDisabled: true,
      });
      return;
    }

    this.fetchAndLoadEmbassiesInCountry(d.value);
  };

  fetchAndLoadSomeEmbassies = (embassy, country) => {
    // Fetches the embassies in a specific country.
    embassyAPIService
      .getAllEmbassiesInCountry(embassy, country, this.props.i18n.language)
      .then((data) => {
        this.setState({
          redirect: true,
          results: data,
          embassy: embassy,
          country: country,
          showResult: true,
          newPath:
            "/" +
            this.props.i18n.language +
            "/search/" +
            embassy +
            "/code/" +
            country,
          isDisabled: false,
        });
      })
      .catch(console.log);
  };

  fetchAndLoadEmbassiesInCountry = (country) => {
    if (this.state.embassy) {
      this.fetchAndLoadSomeEmbassies(this.state.embassy, country);
    }
  };

  showResults = () => {
    if (this.state.showResult && this.state.results) {
      if (this.state.results.embassies) {
        return (
          <EmbassyList
            data={this.state.results.embassies}
            lastUpdate={this.state.results.lastUpdate}
          ></EmbassyList>
        );
      } else {
        return <EmbassyList data={[]}></EmbassyList>;
      }
    } else {
      return (
        <HomeFlagsWidget
          data={this.state.supportedEmbassies}
          lang={this.props.i18n.language}
        />
      );
    }
  };

  getPageTitle = () => {
    let res = "MyEmbassyMyConsulate";

    if (!this.state.embassy) {
      // Main page
      return res;
    }

    const foundEmbassy = this.state.embassies.find(
      (el) => el.key === this.state.embassy
    );
    if (!foundEmbassy) {
      return res;
    }

    res = foundEmbassy.isoText + " | MyEmbassyMyConsulate";
    if (!this.state.country) {
      return res;
    }

    const foundCountry = this.state.countries.find(
      (el) => el.key === this.state.country
    );
    if (!foundCountry) {
      return res;
    }

    return (
      foundEmbassy.isoText +
      " — " +
      foundCountry.isoText +
      " | MyEmbassyMyConsulate"
    );
  };

  getPageHeader = () => {
    let res = this.props.t(
      "world-embassies-high-commissions-consulates-and-visa-centers"
    );

    if (!this.state.embassy) {
      // Main page
      return res;
    }

    const foundEmbassy = this.state.embassies.find(
      (el) => el.key === this.state.embassy
    );
    if (!foundEmbassy) {
      return res;
    }

    res = foundEmbassy.isoText;
    if (!this.state.country) {
      return res;
    }

    const foundCountry = this.state.countries.find(
      (el) => el.key === this.state.country
    );
    if (!foundCountry) {
      return res;
    }

    return foundEmbassy.isoText + " — " + foundCountry.isoText;
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.getPageTitle()}</title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content={this.getPageTitle()} />
        </Helmet>
        {this.state.redirect && <Redirect to={this.state.newPath} />}
        <CustomCountryLogo country={this.state.embassy} />
        <Header as="h1" textAlign="center">
          {this.getPageHeader()}
        </Header>
        <Container textAlign="left">
          <Segment secondary>
            <SearchSelection
              placeholder={this.props.t("of-which-country")}
              action={this.selectEmbassy}
              countries={this.state.embassies}
              value={this.state.embassy}
            />
            {this.state.embassy !== "" && (
              <div>
                <Divider horizontal />
                <SearchSelection
                  placeholder={this.props.t("where")}
                  action={this.selectCountry}
                  countries={this.state.countries}
                  value={this.state.country}
                  isDisabled={this.state.isDisabled}
                />
              </div>
            )}
          </Segment>
          {this.showResults()}
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Home);
