var config = require("../config/config.js");
var backend_url = config.backend_url;

export class EmbassyAPIService {
  // Fetch the available list of embassies and countries.
  async getAllEmbassiesAndCountries(lang) {
    const res = await fetch(backend_url + "/api/v1/countries?lang=" + lang);
    return await res.json();
  }

  // Fetch the available list of embassies.
  async getAllEmbassies(embassyCode, lang) {
    const res = await fetch(
      backend_url + "/api/v1/embassy/" + embassyCode + "?lang=" + lang
    );
    return await res.json();
  }

  // Fetch all embassies in a country.
  async getAllEmbassiesInCountry(embassyCode, countryCode, lang) {
    const res = await fetch(
      backend_url +
        "/api/v1/embassy/" +
        embassyCode +
        "/country/" +
        countryCode +
        "?lang=" +
        lang
    );
    return await res.json();
  }

  // Send Update formular.
  async sendUpdateForm(data) {
    const res = await fetch(backend_url + "/api/v1/embassy/update", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  }

  // Send Contact formular.
  async sendContactForm(data) {
    const res = await fetch(backend_url + "/api/v1/embassy/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return await res.json();
  }
}
