import React from "react";
import { Dropdown } from "semantic-ui-react";

function SearchSelection({
  countries,
  action,
  placeholder,
  value,
  isDisabled,
}) {
  let isLoading = countries.length === 0 ? true : false;

  return (
    <Dropdown
      onChange={action}
      placeholder={placeholder}
      fluid
      search
      selection
      value={value}
      clearable
      options={countries}
      disabled={isDisabled}
      loading={isLoading}
    />
  );
}

export default SearchSelection;
