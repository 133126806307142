import React from "react";
import {
  Segment,
  Container,
  List,
  Icon,
  Divider,
  Image,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";

function Footer() {
  const { i18n, t } = useTranslation();
  return (
    <div>
      <Divider horizontal />
      <Segment inverted vertical color="black">
        <Container textAlign="center">
          <Image src={logo} size="mini" centered href="/" />
          <List inverted size="small">
            <List.Item>
              <List horizontal size="small">
                <List.Item
                  position="right"
                  href="https://www.facebook.com/MyEmbassyMyConsulate"
                  target="_blank"
                >
                  <Icon
                    name="facebook"
                    size="large"
                    style={{ color: "#FFFFFF" }}
                  />
                </List.Item>
                <List.Item
                  position="right"
                  href="https://www.instagram.com/myembassymyconsulate"
                  target="_blank"
                >
                  <Icon
                    name="instagram"
                    size="large"
                    style={{ color: "#FFFFFF" }}
                  />
                </List.Item>
                <List.Item
                  position="right"
                  href="https://pf.kakao.com/_xhwNSs"
                  target="_blank"
                >
                  <Icon
                    name="globe"
                    size="large"
                    style={{ color: "#FFFFFF" }}
                  />
                </List.Item>
                <List.Item
                  position="right"
                  href="https://www.linkedin.com/company/myembassymyconsulate"
                  target="_blank"
                >
                  <Icon
                    name="linkedin"
                    size="large"
                    style={{ color: "#FFFFFF" }}
                  />
                </List.Item>
                <List.Item
                  position="right"
                  href="https://twitter.com/MyEmbassyMyCons"
                  target="_blank"
                >
                  <Icon
                    name="twitter"
                    size="large"
                    style={{ color: "#FFFFFF" }}
                  />
                </List.Item>
                {/* <List.Item position='right' href="https://vk.com/myembassymyconsulate" target="_blank" >
                  <Icon name='vk' size='large' style={{ color: '#FFFFFF' }} />
                </List.Item> */}
              </List>
            </List.Item>
            <List.Item>
              <List horizontal divided inverted size="small">
                <List.Item>
                  <Link to={"/" + i18n.language + "/about"}>
                    <span className="footer-text-policy">{t("about")}</span>
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to={"/" + i18n.language + "/update"}>
                    <span className="footer-text-policy">{t("update")}</span>
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to={"/" + i18n.language + "/donate"}>
                    <span className="footer-text-policy">{t("donate")}</span>
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to={"/" + i18n.language + "/privacy-terms"}>
                    <span className="footer-text-policy">
                      {t("privacy-and-terms")}
                    </span>
                  </Link>
                </List.Item>
                <List.Item>
                  <Link to={"/" + i18n.language + "/contact"}>
                    <span className="footer-text-policy">{t("contact")}</span>
                  </Link>
                </List.Item>
              </List>
            </List.Item>
          </List>
          <div className="footer-text-policy">
            © 2019-2022 MyEmbassyMyConsulate.org. {t("all-rights-reserved")}
          </div>
        </Container>
      </Segment>
    </div>
  );
}

export default Footer;
