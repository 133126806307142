import React from "react";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      style={{ background: "#000000" }}
      buttonStyle={{
        color: "black",
        background: "#FFFFFF",
        borderRadius: "0.255rem",
        fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        fontSize: "13px",
      }}
      buttonText={t("ok")}
      expires={150}
    >
      {t("cookies-sentence")}
    </CookieConsent>
  );
};

export default CookieBanner;
