import React from "react";
import { Container, Divider, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GenericLogo from "../Utils/GenericLogo";

const PrivacyAndTerms = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("privacy-and-terms")} | MyEmbassyMyConsulate</title>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="description" content={t("privacy-and-terms")} />
      </Helmet>
      <GenericLogo />
      <Container textAlign="justified">
        <Divider horizontal>
          <Header as="h1">{t("privacy-and-terms")}</Header>
        </Divider>

        <Header as="h3">1. Introduction</Header>

        <p>
          L'objectif de cette page est de faciliter la compréhension des
          conditions générales d'utilisation et de la gestion de la
          confidentialité pour le Visiteur du Site Internet, en application,
          entre autres, de la loi pour la confiance dans l'économie numérique et
          du règlement général sur la protection des données.
        </p>

        <Header as="h3">2. Définitions</Header>

        <p>
          Contenu : Ensemble des éléments et informations présent sur le Site
          Internet.
        </p>
        <p>
          Donnée(s) Personnelle(s) : Information(s) permettant d'identifier une
          personne physique.
        </p>
        <p>Site Internet : https://www.myembassymyconsulate.org.</p>
        <p>
          Visiteur : Internaute se connectant, utilisant le Site Internet
          susnommé.
        </p>

        <Header as="h3">3. Description</Header>

        <p>
          Le Site Internet est un site internet non gouvernemental et non
          officiel ayant pour objet de fournir des informations consolidées
          concernant les représentations—tout particulièrement
          consulaires—existantes. Le Site Internet souhaite ainsi venir en aide
          aux Visiteurs ayant un besoin d'informations.
        </p>
        <p>
          Propriétaire : myembassymyconsulate.org - OVH - SAS - 2 rue Kellermann
          - 59100 Roubaix - 1007.
        </p>
        <p>
          Responsable publication : myembassymyconsulate.org - OVH - SAS - 2 rue
          Kellermann - 59100 Roubaix - 1007.
        </p>
        <p>
          Webmaster: myembassymyconsulate.org - OVH - SAS - 2 rue Kellermann -
          59100 Roubaix - 1007.
        </p>
        <p>
          Délégué à la protection des données: myembassymyconsulate.org - OVH -
          SAS - 2 rue Kellermann - 59100 Roubaix - 1007.
        </p>
        <p>Hébergeur: OVH - SAS - 2 rue Kellermann - 59100 Roubaix - 1007.</p>
        <p>
          L'utilisation du Site Internet implique l'acceptation pleine et
          entière des dispositions ci - après décrites.Les conditions générales
          d'utilisation, de gestion de la confidentialité et les mentions
          légales du Site Internet sont susceptibles d'être modifiées ou
          complétées à tout moment; le Visiteur est donc invité à les consulter
          de manière régulière.
        </p>

        <Header as="h3">4. Limitation de responsabilité</Header>

        <p>
          Le Site Internet ne pourra être tenu responsable de dommages directs
          ou indirects, matériels ou immatériels, liés à l'utilisation du Site
          Internet.
        </p>
        <p>
          Le Site Internet s'efforce de fournir un Contenu aussi précis et
          adéquat que possible.Toutefois, il ne pourra être tenu responsable
          d'inexactitudes.Toutes les informations proposées sur le Site Internet
          sont données à titre indicatif.Le Site Internet contient également un
          certain nombre de liens hypertextes vers d'autres sites: Il est
          apporté à la connaissance du Visiteur que le contenu de ces sites est
          susceptible de varier de manière continue, sans contrôle possible du
          Responsable de la publication, et que le Site Internet ne pourra être
          tenu responsable de la connexion à ces sites et à leur consultation.
        </p>
        <p>
          Le Visiteur s'engage à accéder au Site Internet en utilisant un
          matériel récent, ne contenant pas de virus et avec un navigateur de
          dernière génération mis à jour.L'utilisation d'un logiciel de contrôle
          parental est également préconisée en cas de consultation de liens
          hypertextes dont le contenu peut varier sans contrôle du Responsable
          de la publication.Le Site Internet ne pourra être tenu responsable des
          dommages directs et indirects causés au matériel de l'utilisateur,
          résultant soit de l'utilisation d'un matériel ne répondant pas aux
          spécifications susnommées, soit de l'apparition d'un bug ou d'une
          incompatibilité.
        </p>
        <p>
          Le Site Internet est hébergé chez un prestataire sur le territoire de
          l'Union Européenne.L'objectif est d'optimiser la sécurité et
          l'accessibilité.L'hébergeur assure la continuité de son service 24
          heures sur 24, tous les jours de l'année.Le Site Internet et
          l'hébergeur se réservent néanmoins la possibilité d'interrompre le
          service d'hébergement, notamment à des fins de maintenance,
          d'amélioration des infrastructures, de défaillance des infrastructures
          ou en cas de trafic réputé anormal.Également, le Site Internet et
          l'hébergeur ne pourront être tenus responsables en cas de
          dysfonctionnement du réseau Internet, des lignes téléphoniques ou du
          matériel informatique et de téléphonie.
        </p>

        <Header as="h3">5. Propriété intellectuelle</Header>

        <p>
          Le Site Internet constitue une œuvre de l'esprit protégée par les
          dispositions des Réglementations Nationales et Internationales
          applicables.Toute utilisation, reproduction, représentation,
          modification, publication, adaptation de tout ou partie des
          informations du Site Internet crées par le propriétaire du Site
          Internet(e.g., procédés, textes, images, graphismes, logos, vidéos,
          icônes et sons applicables), quel que soit le moyen ou le procédé
          utilisé, est interdite.Toute exploitation non autorisée du Site
          Internet ou de l'un quelconque des éléments applicables qu'il
          contient, également possiblement considérée comme constitutive d'une
          contrefaçon, sera susceptible d'être poursuivie.
        </p>
        <p>
          Les liens vers le présent Site Internet sont autorisés dans la mesure
          où ils ne portent pas préjudice à son image.Toute citation du présent
          site devra faire figurer: le nom du site, sous une des formes
          suivantes: "MyEmbassyMyConsulate", "MyEmbassyMyConsulate.org" ou
          "www.MyEmbassyMyConsulate.org".Est interdite toute technique ou
          pratique susceptible d'engendrer une confusion dans l'esprit du
          public, notamment quant à l'origine de l'information.
        </p>

        <Header as="h3">
          6. Données Personnelles, cookies, balises internet & informations
          tierces
        </Header>

        <p>
          Le Site Internet ne recueille pas de Données Personnelles.En cas
          d'utilisation du formulaire de contact, le Visiteur est enjoint à ne
          pas saisir de Données Personnelles.Les informations recueillies sont
          conservées sur une boite de réception e - mail sécurisée, entre
          autres, par un mot de passe.Elles sont conservées en durée selon les
          directives applicables.Les terminaux où ces données sont
          accessibles(e.g., ordinateur, appareil mobile) sont protégés, entre
          autres, par un mot de passe.En cas de réception de Données
          Personnelles, toutes les mesures raisonnables pour s'assurer de leur
          confidentialité seront prises.Aucune Donnée Personnelle du Visiteur du
          Site Internet n'est publiée à l'insu du Visiteur, échangée,
          transférée, cédée ou vendue sur un support quelconque à des
          tiers.Seule l'hypothèse du rachat du Site Internet et de ses droits
          permettrait la transmission des dites Données Personnelles à
          l'éventuel acquéreur qui serait à son tour tenu de la même obligation
          de conservation et de modification des données vis à vis du Visiteur
          du Site Internet.
        </p>
        <p>
          Le Visiteur dispose d'un droit d'accès, de rectification et
          d'opposition aux Données Personnelles le concernant en contactant le
          Délégué à la protection des données du Site Internet qui supprimera ou
          modifiera les données afférentes.Le Site Internet se réserve la
          possibilité de mettre en cause la responsabilité civile et / ou pénale
          du Visiteur, notamment en cas de message à caractère raciste,
          injurieux, diffamant, ou pornographique, quel que soit le support
          utilisé(e.g., texte, photographie).La CNIL propose également un
          portail avec des conseils et informations liés.
        </p>

        <p>
          La navigation sur le Site Internet est susceptible de provoquer
          l'installation de cookie(s) sur l'ordinateur du Visiteur.Un cookie est
          un fichier de petite taille, qui ne permet pas l'identification du
          Visiteur, mais qui enregistre des informations relatives à la
          navigation d'un ordinateur sur un site.De manière automatique,
          certaines informations concernant le Visiteur lors d'une simple
          navigation sur le Site Internet sont collectées, notamment : le
          fournisseur d'accès, l'URL des liens par l'intermédiaire desquels le
          Visiteur a accédé au Site Internet, les pages consultées, l'adresse de
          protocole Internet(IP), le type de navigateur, le temps d'accès.De
          telles informations sont utilisées à des fins de statistiques, de
          sécurité du Site Internet, pour améliorer la qualité et le Contenu du
          Site Internet, et la navigation du Visiteur.Les cookies sont conservés
          en durée selon les directives applicables.Également, en cliquant sur
          certain(e)s icônes / liens hypertextes(e.g., Facebook, LinkedIn,
          Twitter) figurant sur le Site Internet, l'installation de cookies sur
          les terminaux du Visiteur est susceptible de se poursuivre. Le Site
          Internet utilise des solutions de prestataires tiers, présentant des
          garanties suffisantes au regard des exigences des directives
          applicables, qui nécessitent l'utilisation de cookies récoltant les
          informations susnommées.Comme outil de statistiques, ce site utilise
          notamment Google Analytics.Le texte type suivant entre guillemets est
          proposé: « Ce site utilise Google Analytics, un service d'analyse de
          site internet fourni par Google Inc. (« Google »).Google Analytics
          utilise des cookies, qui sont des fichiers texte placés sur votre
          ordinateur, pour aider le site internet à analyser l'utilisation du
          site par ses utilisateurs.Les données générées par les cookies
          concernant votre utilisation du site(y compris votre adresse IP)
          seront transmises et stockées par Google sur des serveurs situés aux
          Etats - Unis.Google utilisera cette information dans le but d'évaluer
          votre utilisation du site, de compiler des rapports sur l'activité du
          site à destination de son éditeur et de fournir d'autres services
          relatifs à l'activité du site et à l'utilisation d'Internet.Google est
          susceptible de communiquer ces données à des tiers en cas d'obligation
          légale ou lorsque ces tiers traitent ces données pour le compte de
          Google, y compris notamment l'éditeur de ce site.Google ne recoupera
          pas votre adresse IP avec toute autre donnée détenue par Google.Vous
          pouvez désactiver l'utilisation de cookies en sélectionnant les
          paramètres appropriés de votre navigateur.Cependant, une telle
          désactivation pourrait empêcher l'utilisation de certaines
          fonctionnalités de ce site.En utilisant ce site internet, vous
          consentez expressément au traitement de vos données nominatives par
          Google dans les conditions et pour les finalités décrites ci -
          dessus.» Le Site Internet peut employer occasionnellement des balises
          Internet(également appelées « tags », ou balises d'action, GIF
          transparents et similaires) et les déployer par l'intermédiaire d'un
          partenaire spécialiste d'analyses Web susceptible de se trouver(et
          donc de stocker les informations correspondantes, y compris l'adresse
          IP du Visiteur) dans un pays étranger.Ces balises sont placées à la
          fois dans les publicités en ligne permettant aux Visiteurs d'accéder
          au Site, et sur les différentes pages de celui - ci.Cette technologie
          permet au Site Internet d'évaluer les réponses des Visiteurs face au
          Site Internet et l'efficacité de ses actions(par exemple, le nombre de
          fois où une page est ouverte et les informations consultées), ainsi
          que l'utilisation de ce Site Internet par le Visiteur.Le prestataire
          externe pourra éventuellement recueillir des informations sur les
          Visiteurs du Site Internet et d'autres sites Internet grâce à ces
          balises, constituer des rapports sur l'activité du Site Internet, et
          fournir d'autres services relatifs à l'utilisation de celui - ci et
          d'Internet. Le non - consentement de l'installation d'un cookie peut
          entraîner l'impossibilité d'accéder à certaines fonctionnalités du
          Site Internet, relatifs en particulier à la facilité de navigation, à
          l'affichage, aux paramètres de langue et de pays. Le Visiteur peut
          configurer son ordinateur ou support mobile pour refuser
          l'installation des cookies.Par exemple, les navigateurs Internet
          Explorer, Firefox, Safari et Chrome proposent de désactiver les
          cookies.Cette option est généralement accessible via les onglets
          options / outils / paramètres internet puis confidentialité / vie
          privée.Le Visiteur est encouragé à consulter régulièrement les aides /
          FAQ proposées par les éditeurs des navigateurs susnommées.
        </p>

        <p>
          Quels que soient les efforts fournis, aucune méthode de transmission
          sur Internet et aucune méthode de stockage électronique n'est
          complètement sûre. Nous ne pouvons en conséquence pas garantir une
          sécurité absolue. Nous prenons toutes les mesures raisonnables pour
          assurer la gestion de la confidentialité.
        </p>

        <Header as="h3">7. Autres</Header>

        <p>
          Tout litige en relation avec l'utilisation du Site Internet est soumis
          au droit français.Il est fait attribution exclusive aux juridictions
          compétentes, nommées ultérieurement, de France.
        </p>

        <Header as="h3">8. Principales Directives Applicables</Header>

        <p>
          Les principales directives applicables sont, entre autres, la loi n°
          78 - 17 du 6 janvier 1978, la directive européenne du 24 octobre 1995,
          la loi n° 2004 - 575 du 21 juin 2004 pour la confiance dans l'économie
          numérique, la loi informatique et libertés n° 2004-801 du 6 Août 2004
          et le règlement général sur la protection des données (RGPD) n°
          2016-679.
        </p>
      </Container>
    </div>
  );
};

export default PrivacyAndTerms;
