import React from "react";
import { Container, Divider, Header, Grid, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GenericLogo from "./Utils/GenericLogo";
import bmec from "../assets/images/bmac.png";

const showLinks = () => {
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Container textAlign="right">
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_blank"
            >
              <input
                type="hidden"
                name="hosted_button_id"
                value="83QYM7RC9VE9S"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/FR/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/en_FR/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Image
            src={bmec}
            size="small"
            target="_blank"
            href="https://www.buymeacoffee.com/MyEmbassyMyCons"
            floated="left"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const Donate = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>{t("donate")} | MyEmbassyMyConsulate</title>
        <meta name="description" content={t("donate")} />
      </Helmet>
      <GenericLogo />
      <Container textAlign="justified">
        <Divider horizontal>
          <Header as="h1">{t("donate")}</Header>
        </Divider>
        <b>
          <p>{t("about-paragraph-1")}</p>
          <p>{t("about-paragraph-2")}</p>
          <p>{t("about-paragraph-3")}</p>
          <p>{t("about-paragraph-4")}</p>
        </b>
        <br />
        <br />
        <br />
        <Container textAlign="center">{showLinks()}</Container>
      </Container>
    </div>
  );
};

export default Donate;
