import React, { Component } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Donate from "./components/Donate";
import Update from "./components/Update";
import PrivacyAndTerms from "./components/PrivacyAndTerms/PrivacyAndTerms";
import CustomFooter from "./components/CustomFooter";
import CustomMenu from "./components/CustomMenu";
import PageNotFound from "./components/Utils/PageNotFound";
import { withTranslation } from "react-i18next";
import CookieBanner from "./components/CookieBanner";

var refresh = true;

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "home",
    };
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    return (
      <div className="Site">
        <CookieBanner />
        <BrowserRouter>
          <div className="Site-content">
            <CustomMenu />
            <div className="under-construction">Under construction</div>
            <Switch>
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/about"
                component={About}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/contact"
                component={Contact}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/donate"
                component={Donate}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/update"
                component={Update}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/privacy-terms"
                component={PrivacyAndTerms}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/search/:id([A-Za-z][A-Za-z])/code/:country([A-Za-z][A-Za-z])"
                component={Home}
              />
              <Route
                exact
                path="/:lang([A-Za-z][A-Za-z])/search/:id([A-Za-z][A-Za-z])"
                component={Home}
              />
              <Route exact path="/:lang([A-Za-z][A-Za-z])" component={Home} />
              <Route exact path="/">
                <Redirect to={"/" + this.props.i18n.language} />
              </Route>
              {/* <Route component={PageNotFound} status={404} /> */}
            </Switch>
          </div>
          <CustomFooter />
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation()(Main);
