import React from "react";
import { Divider, Image, Container } from "semantic-ui-react";
import logo from "../../assets/images/logo.png";

const GenericLogo = () => {
  return (
    <Container textAlign="center">
      <Divider horizontal />
      <Image src={logo} href={"/"} size="small" centered />
      <Divider horizontal />
    </Container>
  );
};

export default GenericLogo;
