import React from "react";
import { Icon, Item, List } from "semantic-ui-react";

const SocialNetworks = ({ networks, website1, website2 }) => {
  const buildSocialNetwork = (name, url) => {
    if (url) {
      return (
        <Item href={url} target="_blank">
          <Icon name={name} size="big" color="black" />
        </Item>
      );
    }
  };

  return (
    <List horizontal size="mini">
      {buildSocialNetwork("chain", website1)}
      {buildSocialNetwork("chain", website2)}
      {buildSocialNetwork("facebook", networks.facebook1)}
      {buildSocialNetwork("facebook", networks.facebook2)}
      {buildSocialNetwork("flickr", networks.flickr)}
      {buildSocialNetwork("instagram", networks.instagram)}
      {buildSocialNetwork("linkedin", networks.linkedin)}
      {buildSocialNetwork("odnoklassniki square", networks.ok)}
      {buildSocialNetwork("pinterest square", networks.pinterest)}
      {buildSocialNetwork("soundcloud", networks.soundcloud)}
      {buildSocialNetwork("star", networks.spreaker)}
      {buildSocialNetwork("telegram plane", networks.telegram)}
      {buildSocialNetwork("twitter square", networks.twitter1)}
      {buildSocialNetwork("twitter square", networks.twitter2)}
      {buildSocialNetwork("vimeo square", networks.vimeo)}
      {buildSocialNetwork("vk", networks.vk)}
      {buildSocialNetwork("weibo", networks.weibo)}
      {buildSocialNetwork("wechat", networks.wechat)}
      {buildSocialNetwork("whatsapp square", networks.whatsapp)}
      {buildSocialNetwork("wordpress", networks.wordpress)}
      {buildSocialNetwork("youtube", networks.youtube1)}
      {buildSocialNetwork("globe", networks.blogspot)}
      {buildSocialNetwork("globe", networks.douyin)}
      {buildSocialNetwork("globe", networks.giphy)}
      {buildSocialNetwork("globe", networks.medium)}
      {buildSocialNetwork("globe", networks.podbean)}
      {buildSocialNetwork("globe", networks.sinablog)}
      {buildSocialNetwork("globe", networks.zalo)}
    </List>
  );
};

export default SocialNetworks;
