import React from "react";
import { Container, Divider, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import GenericLogo from "./GenericLogo";

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <div>
      <GenericLogo />
      <Container textAlign="center">
        <Divider horizontal />
        <Header as="h1" textAlign="center">
          {t("sorry-this-page-is-not-available")}
        </Header>
        <Divider horizontal />
      </Container>
    </div>
  );
}

export default PageNotFound;
