import React from "react";
import { Link } from "react-router-dom";
import {
  Segment,
  List,
  ListItem,
  Header,
  Icon,
  Container,
} from "semantic-ui-react";
import Iframe from "react-iframe";
import SocialNetworks from "./Utils/SocialNetworks";
import { useTranslation } from "react-i18next";

const EmbassyInfo = ({ embassy }) => {
  const { t, i18n } = useTranslation();
  const buildIFrame = (geo) => {
    if (geo === null || !("mapurl" in geo)) {
      return;
    }

    return (
      <Iframe url={geo.mapurl} width="100%" height="100%" loading="lazy" />
    );
  };

  const buildPerson = () => {
    if (embassy.person) {
      return (
        <ListItem>
          <List.Content>
            <Icon name="user" />
            {embassy.person}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildAddress = (address, geo) => {
    if (address) {
      let mapurl = "";
      if (geo !== null) {
        mapurl = geo.gmapurl;
      }

      return (
        <ListItem>
          <List.Content href={mapurl} className="bloc-result">
            <Icon name="marker" />
            {address}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildPhone = (phone) => {
    if (phone) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"tel:" + phone}>
            <Icon name="phone" />
            {phone}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildFax = (fax) => {
    if (fax) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"fax:" + fax}>
            <Icon name="fax" />
            {fax}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildEmailGeneral = (mail) => {
    if (mail) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"mailto:" + mail}>
            <Icon name="mail" />
            {mail}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildEmailConsular = (mail) => {
    if (mail) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"mailto:" + mail}>
            <Icon name="mail" />
            {mail} {t("consular")}
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildEmailCitizens = (mail, code) => {
    if (mail) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"mailto:" + mail}>
            <Icon name="mail" />
            {mail} (<Icon name="check" color="green" /> {code}/{t("citizens")})
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildEmailNonCitizens = (mail, code) => {
    if (mail) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"mailto:" + mail}>
            <Icon name="mail" />
            {mail} (<Icon name="close" color="red" /> {code}/{t("citizens")})
          </List.Content>
        </ListItem>
      );
    }
  };

  const buildEmailsVisa = (mail) => {
    if (mail) {
      return (
        <ListItem>
          <List.Content className="bloc-result" href={"mailto:" + mail}>
            <Icon name="mail" />
            {mail} {t("visas")}
          </List.Content>
        </ListItem>
      );
    }
  };

  return (
    <Segment>
      <Container textAlign="center">
        <Header as="h2">
          {embassy.type} — {embassy.city} ({embassy.country})
        </Header>
      </Container>
      <br />
      {buildIFrame(embassy.geo)}
      <br />
      <Container textAlign="center">
        <SocialNetworks
          networks={embassy.networks}
          website1={embassy.website1}
          website2={embassy.website2}
        />
        <List>
          {buildPerson()}
          {buildAddress(embassy.address, embassy.geo)}
          {buildPhone(embassy.phone1)}
          {buildFax(embassy.fax1)}
          {buildEmailGeneral(embassy.emailgeneral1)}
          {buildEmailGeneral(embassy.emailgeneral2)}
          {buildEmailGeneral(embassy.emailgeneral3)}
          {buildEmailGeneral(embassy.emailgeneral4)}
          {buildEmailConsular(embassy.emailconsular1)}
          {buildEmailConsular(embassy.emailconsular2)}
          {buildEmailCitizens(
            embassy.emailcitizens1,
            embassy.embassycode.toUpperCase()
          )}
          {buildEmailCitizens(
            embassy.emailcitizens2,
            embassy.embassycode.toUpperCase()
          )}
          {buildEmailCitizens(
            embassy.emailcitizens3,
            embassy.embassycode.toUpperCase()
          )}
          {buildEmailCitizens(
            embassy.emailcitizens4,
            embassy.embassycode.toUpperCase()
          )}
          {buildEmailNonCitizens(
            embassy.emailnoncitizens1,
            embassy.embassycode.toUpperCase()
          )}
          {buildEmailsVisa(embassy.emailsvisa1)}
          {buildEmailsVisa(embassy.emailsvisa2)}
          {buildEmailsVisa(embassy.emailsvisa3)}
          <List.Item>
            <Link
              to={{
                pathname: "/" + i18n.language + "/update",
                state: {
                  embassy: embassy,
                },
              }}
            >
              <Icon name="sync" color="black"></Icon>
            </Link>
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
};

export default EmbassyInfo;
