export function BuildCountriesList(data) {
  return data.map((el) => {
    return {
      key: el.code.toLowerCase(),
      value: el.code.toLowerCase(),
      flag: el.code.toLowerCase(),
      text: "(" + el.code + ") " + el.name,
      isoText: el.name + " (" + el.code + ")",
    };
  });
}

export function IsFound(elements, val) {
  const found = elements.find(
    (el) => el.code.toLowerCase() === val.toLowerCase()
  );
  if (!found) {
    return false;
  }
  return true;
}
