import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  List,
  Image,
  Dropdown,
  Icon,
  Button,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";

const language = [
  {
    value: "en",
    text: "English",
  },
  {
    value: "es",
    text: "Español",
  },
  {
    value: "fr",
    text: "Français",
  },
  {
    value: "ms",
    text: "Melayu (beta)",
  },
  {
    value: "ru",
    text: "Русский (beta)",
  },
  {
    value: "vi",
    text: "Tiếng Việt (beta)",
  },
  {
    value: "ar",
    text: "العربية (beta)",
  },
  {
    value: "zh",
    text: "中文 (简体) (beta)",
  },
  {
    value: "ko",
    text: "한국어 (beta)",
  },
  {
    value: "lo",
    text: "ລາວ (beta)",
  },
];

function CustomMenu() {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const handleClick = (e, { value }) => {
    i18n.changeLanguage(value);
    window.location.reload(false);
    history.push("/");
  };

  return (
    <Segment inverted vertical color="black">
      <Container textAlign="right">
        <List inverted horizontal divided size="small">
          <List.Item>
            <Image src={logo} width={18} centered href={"/" + i18n.language} />
          </List.Item>
          <List.Item>
            <List.Content>
              <Link
                to={"/" + i18n.language + "/update"}
                className="menu-text-policy"
              >
                <List.Header>
                  <span className="menu-text-policy">{t("update")}</span>
                </List.Header>
              </Link>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <Link
                to={"/" + i18n.language + "/donate"}
                className="menu-text-donate-policy"
              >
                <Button compact inverted>
                  {t("donate")}
                </Button>
              </Link>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <Dropdown
                trigger={
                  <span className="menu-text-policy">
                    <Icon className="no-margin" name="language" size="large" />
                  </span>
                }
                options={language}
                onChange={handleClick}
              ></Dropdown>
            </List.Content>
          </List.Item>
          <List.Item></List.Item>
        </List>
      </Container>
    </Segment>
  );
}

export default CustomMenu;
