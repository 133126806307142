import { Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const sliceIntoChunks = (arr, chunkSize) => {
  var res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    var chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const countryTemplate = (el, lang) => {
  let code = el.code.toLowerCase();
  return (
    <div>
      <Link
        to={{
          pathname: "/" + lang + "/search/" + code,
          state: code,
        }}
      >
        <Image
          data-tip
          data-for={el.code}
          src={process.env.PUBLIC_URL + "/flags/" + code + ".svg"}
          size="mini"
          centered
        />
      </Link>
      <ReactTooltip id={el.code} place="top" effect="solid">
        {el.name}
      </ReactTooltip>
    </div>
  );
};

const buildColumns = (data, lang) => {
  return data.map((el) => {
    return (
      <Grid.Column keys={el.code}>{countryTemplate(el, lang)}</Grid.Column>
    );
  });
};

const HomeFlagsWidget = ({ data, lang }) => {
  let res = sliceIntoChunks(data, 7);

  return (
    <Grid columns={7}>
      {res.map((el) => {
        return <Grid.Row key={el}>{buildColumns(el, lang)}</Grid.Row>;
      })}
    </Grid>
  );
};

export default HomeFlagsWidget;
