import { Container, List } from "semantic-ui-react";
import {
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  EmailShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  TwitterIcon,
  RedditIcon,
  EmailIcon,
  TelegramIcon,
  VKIcon,
  WhatsappIcon,
  WeiboIcon,
  PinterestShareButton,
  PinterestIcon,
} from "react-share";

const SharedButtons = (url) => {
  const iconSize = 28;
  return (
    <Container textAlign="center">
      <List horizontal>
        <List.Item>
          <EmailShareButton url={url} quote="MEMC">
            <EmailIcon size={iconSize} round />
          </EmailShareButton>
        </List.Item>
        <List.Item>
          <FacebookShareButton url={url} quote="MEMC">
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
        </List.Item>
        <List.Item>
          <LineShareButton url={url} quote="MEMC">
            <LineIcon size={iconSize} round />
          </LineShareButton>
        </List.Item>
        <List.Item>
          <LinkedinShareButton url={url} quote="MEMC">
            <LinkedinIcon size={iconSize} round />
          </LinkedinShareButton>
        </List.Item>
        <List.Item>
          <PinterestShareButton media={url} title="MEMC">
            <PinterestIcon size={iconSize} round />
          </PinterestShareButton>
        </List.Item>
        <List.Item>
          <RedditShareButton url={url} title="MEMC">
            <RedditIcon size={iconSize} round />
          </RedditShareButton>
        </List.Item>
        <List.Item>
          <TelegramShareButton url={url} title="MEMC">
            <TelegramIcon size={iconSize} round />
          </TelegramShareButton>
        </List.Item>
        <List.Item>
          <TwitterShareButton url={url} title="MEMC">
            <TwitterIcon size={iconSize} round />
          </TwitterShareButton>
        </List.Item>
        <List.Item>
          <VKShareButton url={url} title="MEMC">
            <VKIcon size={iconSize} round />
          </VKShareButton>
        </List.Item>
        <List.Item>
          <WeiboShareButton url={url} quote="MEMC">
            <WeiboIcon size={iconSize} round />
          </WeiboShareButton>
        </List.Item>
        <List.Item>
          <WhatsappShareButton url={url} title="MEMC">
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>
        </List.Item>
      </List>
    </Container>
  );
};

export default SharedButtons;
