import React, { Component } from "react";
import {
  Container,
  Divider,
  Header,
  Form,
  Button,
  Message,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GenericLogo from "./Utils/GenericLogo";
import { EmbassyAPIService } from "../services/EmbassyAPIService";

const embassyAPIService = new EmbassyAPIService();

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      description: "",
      submitted: false,
      subjectError: false,
      descriptionError: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, d) {
    const field = event.target.id;
    if (field === "name") {
      this.setState({ name: event.target.value });
    } else if (field === "email") {
      this.setState({ email: event.target.value });
    } else if (field === "subject") {
      this.setState({ subject: event.target.value });
    } else if (field === "description") {
      this.setState({ description: d.value });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ subjectError: false, descriptionError: false });

    // Check mandatory fields.
    if (this.state.subject === "") {
      this.setState({ subjectError: true });
    }
    if (this.state.description === "") {
      this.setState({ descriptionError: true });
    }

    if (this.state.subject === "" || this.state.description === "") {
      return;
    }

    this.setState({ submitted: true });

    // Call backend API.
    embassyAPIService.sendContactForm(this.state);
  }

  showForm() {
    if (!this.state.submitted) {
      return (
        <Container textAlign="left">
          <Form>
            <Form.Field>
              <label>{this.props.t("name")}</label>
              <input
                id="name"
                type="text"
                placeholder={this.props.t("name")}
                value={this.state.name}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("email")}</label>
              <input
                id="email"
                type="text"
                placeholder={this.props.t("email")}
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.Field required error={this.state.subjectError}>
              <label>{this.props.t("subject")}</label>
              <input
                id="subject"
                type="text"
                placeholder={this.props.t("subject")}
                required
                value={this.state.subject}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.TextArea
              id="description"
              label={this.props.t("text")}
              required
              placeholder={this.props.t("text")}
              value={this.state.description}
              error={this.state.descriptionError}
              onChange={this.handleChange.bind(this)}
            />
            <Button type="submit" attached="bottom" onClick={this.handleSubmit}>
              {this.props.t("send")}
            </Button>
          </Form>
        </Container>
      );
    }
  }

  showSubmitMessage() {
    if (this.state.submitted) {
      return (
        <Message
          success
          header="Message submitted"
          content="Thanks for your update."
        />
      );
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.t("contact")} | MyEmbassyMyConsulate</title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content={this.props.t("contact")} />
        </Helmet>
        <GenericLogo />
        <Container textAlign="center">
          <Divider horizontal>
            <Header as="h1">{this.props.t("contact")}</Header>
          </Divider>
          {this.showForm()}
          {this.showSubmitMessage()}
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Contact);
