import React, { Component } from "react";
import {
  Container,
  Divider,
  Header,
  Form,
  Button,
  Message,
  Dropdown,
  DropdownMenu,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GenericLogo from "./Utils/GenericLogo";
import { EmbassyAPIService } from "../services/EmbassyAPIService";

const embassyAPIService = new EmbassyAPIService();

var config = require("../config/config.js");
var backend_url = config.backend_url;

const options = [
  { key: "o", text: "Opening", value: "opening" },
  { key: "u", text: "Update of contact information", value: "update" },
  { key: "s", text: "Suspension", value: "suspension" },
  { key: "c", text: "Closure", value: "closure" },
];

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      description: "",
      embassy: "",
      country: "",
      city: "",
      action: "",
      submitted: false,
      actionError: false,
      descriptionError: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      const el = this.props.location.state.embassy;
      this.setState({
        embassy: el.embassy,
        type: el.type,
        country: el.country,
        city: el.city,
        action: "",
      });
    }
  }

  handleChange(event, d) {
    const field = event.target.id;
    if (
      field === "opening" ||
      field == "update" ||
      field == "suspension" ||
      field == "closure"
    ) {
      this.setState({ action: field });
    } else if (field === "type") {
      this.setState({ type: event.target.value });
    } else if (field === "embassy") {
      this.setState({ embassy: event.target.value });
    } else if (field === "country") {
      this.setState({ country: event.target.value });
    } else if (field === "city") {
      this.setState({ city: event.target.value });
    } else if (field === "description") {
      this.setState({ description: d.value });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ actionError: false, descriptionError: false });

    // Check mandatory fields.
    if (this.state.action === "") {
      this.setState({ actionError: true });
    }
    if (this.state.description === "") {
      this.setState({ descriptionError: true });
    }

    if (this.state.action === "" || this.state.description === "") {
      return;
    }

    this.setState({ submitted: true });

    // Fill the JSON request body.
    var req = {
      type: this.state.action,
      posttype: this.state.type,
      ofwhichcountry: this.state.embassy,
      wherecountry: this.state.country,
      wherecity: this.state.city,
      text: this.state.description,
    };

    // Call backend API.
    embassyAPIService.sendUpdateForm(req);
  }

  showForm() {
    if (!this.state.submitted) {
      return (
        <Container textAlign="left">
          <Form>
            <Form.Select
              fluid
              id="action"
              label={this.props.t("update-type")}
              options={options}
              placeholder={this.props.t("update-type")}
              required
              error={this.state.actionError}
              value={this.state.action}
            >
              <DropdownMenu key="o" onClick={this.handleChange.bind(this)}>
                <Dropdown.Item id="opening">
                  {this.props.t("opening")}
                </Dropdown.Item>
                <Dropdown.Item id="update">
                  {this.props.t("update-of-contact-information")}
                </Dropdown.Item>
                <Dropdown.Item id="suspension">
                  {this.props.t("suspension")}
                </Dropdown.Item>
                <Dropdown.Item id="closure">
                  {this.props.t("closure")}
                </Dropdown.Item>
              </DropdownMenu>
            </Form.Select>
            <Form.Field>
              <label>
                {this.props.t(
                  "post-type-embassy-high-commission-consulate-visa-center"
                )}
              </label>
              <input
                id="type"
                type="text"
                placeholder={this.props.t(
                  "post-type-embassy-high-commission-consulate-visa-center"
                )}
                value={this.state.type}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("of-which-country")}</label>
              <input
                id="embassy"
                type="text"
                placeholder={this.props.t("of-which-country")}
                value={this.state.embassy}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("where-country")}</label>
              <input
                id="country"
                type="text"
                placeholder={this.props.t("where-country")}
                value={this.state.country}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.Field>
              <label>{this.props.t("where-city")}</label>
              <input
                id="city"
                type="text"
                placeholder={this.props.t("where-city")}
                value={this.state.city}
                onChange={this.handleChange.bind(this)}
              />
            </Form.Field>
            <Form.TextArea
              id="description"
              label={this.props.t("text")}
              required
              placeholder={this.props.t("text")}
              value={this.state.description}
              error={this.state.descriptionError}
              onChange={this.handleChange.bind(this)}
            />
            <Button type="submit" attached="bottom" onClick={this.handleSubmit}>
              {this.props.t("send")}
            </Button>
          </Form>
        </Container>
      );
    }
  }

  showSubmitMessage() {
    if (this.state.submitted) {
      return <Message success content={this.props.t("thank-you-very-much")} />;
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.t("update")} | MyEmbassyMyConsulate</title>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content={this.props.t("update")} />
        </Helmet>
        <GenericLogo />
        <Container textAlign="center">
          <Divider horizontal>
            <Header as="h1">{this.props.t("update")}</Header>
          </Divider>
          {this.showForm()}
          {this.showSubmitMessage()}
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Update);
