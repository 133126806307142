import React from "react";

import { Divider, Image, Container } from "semantic-ui-react";

const CustomCountryLogo = (data) => {
  let logoName;

  if (data.country === "") {
    logoName = "logo.png";
  } else {
    logoName = "logo-MEMC-" + data.country.toUpperCase() + ".svg";
  }

  return (
    <Container textAlign="center">
      <Divider horizontal />
      <Image
        src={process.env.PUBLIC_URL + "/logos/" + logoName}
        href={"/" + data.country}
        size="small"
        centered
      />
      <Divider horizontal />
    </Container>
  );
};

export default CustomCountryLogo;
