import React from "react";
import { Grid, Segment, Container, Pagination } from "semantic-ui-react";
import EmbassyInfo from "./EmbassyInfo";
import { withTranslation } from "react-i18next";
import SharedButtons from "./Utils/SharedButtons";

const NB_RESULTS_PER_PAGE = 5;

class EmbassyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEmbassies: [],
    };

    this.showResults = this.showResults.bind(this);
  }

  componentDidMount() {
    this.setState({
      pageEmbassies: this.props.data.slice(0, NB_RESULTS_PER_PAGE),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        pageEmbassies: this.props.data.slice(0, NB_RESULTS_PER_PAGE),
      });
    }
  }

  getNbResults() {
    return this.props.data.length;
  }

  showResults = (event, data) => {
    let begin = data.activePage * NB_RESULTS_PER_PAGE - NB_RESULTS_PER_PAGE;
    let end = data.activePage * NB_RESULTS_PER_PAGE;

    this.setState({
      pageEmbassies: this.props.data.slice(begin, end),
    });
  };

  showNbResults = (nb) => {
    var num = this.getNbResults();

    if (num == 1) {
      return (
        <b>
          {" "}
          {num} {this.props.t("result")}
        </b>
      );
    }

    return (
      <b>
        {" "}
        {num} {this.props.t("results")}
      </b>
    );
  };

  render() {
    if (this.props.data.length === 0) {
      return (
        <div>
          <Segment>
            <b>0 {this.props.t("results")}</b>
          </Segment>
        </div>
      );
    }

    return (
      <div>
        <Segment>
          <Grid columns={2}>
            <Grid.Column>{this.showNbResults(this.getNbResults())}</Grid.Column>
            <Grid.Column textAlign="right">
              {this.props.t("last-update")} {this.props.lastUpdate}
            </Grid.Column>
          </Grid>
        </Segment>
        <SharedButtons url={window.location.href} />
        {(this.state.pageEmbassies || []).map(function (data, i) {
          return <EmbassyInfo key={data.id} embassy={data} />;
        })}
        <Container textAlign="center">
          <Pagination
            defaultActivePage={1}
            totalPages={Math.ceil(this.props.data.length / NB_RESULTS_PER_PAGE)}
            onPageChange={this.showResults}
            siblingRange={0}
            boundaryRange={1}
            size="mini"
            firstItem={null}
            lastItem={null}
            secondary
          />
        </Container>
      </div>
    );
  }
}

export default withTranslation()(EmbassyList);
