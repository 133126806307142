import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import Favicon from 'react-favicon';
import "./index.css";
import "./services/i18n";
import 'semantic-ui-css/semantic.min.css';
import logo from './assets/images/logo.png';

document.title = "MyEmbassyMyConsulate"

ReactDOM.render(
  <div>
    <Favicon url={logo} />
    <React.StrictMode>
      <React.Suspense fallback="">
        <Main />
      </React.Suspense>
    </React.StrictMode>
  </div >,
  document.getElementById("root")
);